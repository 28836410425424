import React, {useEffect, useState} from 'react';
import history from "../history/history";
import {StyleSheet, View, Text, Platform} from 'react-native';
import WebView from "react-native-webview";
import {userSession} from "../api/userSession";

const style = StyleSheet.create({
  videoCall: {
    flexGrow: 1,
    width: "100%",
  },
  centeredMsg: {
    flexGrow: "1",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    fontSize: 24,
    textAlign: "center",
    padding: 20,
  }
});

export function VideoCall({route}) {
  const meeting = route.params.meeting;
  const [token, setToken] = useState(null)
  const [name, setName] = useState(null)

  if (!meeting) history().goBack();
  
  useEffect(() => {
    retrieveSessionInfo();
  }, []);
  
  async function retrieveSessionInfo() {
    const name = await userSession.getName();
    const token = await userSession.getToken();
    setToken(token);
    setName(name);
    if (Platform.OS === "web") {
      window.open(`https://call.cerah.co/${meeting._id}?courseId=${meeting.course._id}&token=${token}&name=${name}&host=true`, '_blank');
    }
  }
  
  const uri = `https://call.cerah.co/${meeting._id}?courseId=${meeting.course._id}&token=${token}&name=${name}`;
  
  return (
    <View style={style.videoCall}>
      {(Platform.OS === "web") ?
        <View style={style.centeredMsg}>
          <Text style={style.text}>Video call will open on a new browser tab</Text>
        </View> :
        <WebView source={{ uri }} />
      }
    </View>
  );
}
