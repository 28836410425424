import { useEffect, useState } from "react"
import React from "react"
import { courseApi } from "../api/courseApi";
import { View, Text, Image, StyleSheet, ScrollView, TouchableOpacity } from "react-native";
import moment from "moment-timezone";
import {Spinner} from "../controls/spinner";
import {palette} from "../style/palette";

const style = StyleSheet.create({
  paidStamp: {
    position: "absolute",
    zIndex: 100,
    right: 20,
    fontWeight: "bold",
    transform: [{ rotate: '33deg' }],
    fontSize: 20,
    top: 12,
    color: palette.$accent1,
  },
  payslipItem: {
    backgroundColor: "white",
    margin: 2,
    padding: 20,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5
  },
  payslipInfo: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
  pending: {
    marginHorizontal: 20,
    marginVertical: 40,
    borderRadius: 20,
  },
  new: {
    fontSize: 18,
    fontWeight: "800",
    color: "#f00",
    marginBottom: 10
  },
  reviewLabel: {
    fontSize: 14,
    fontWeight: "800",
    color: palette.$accent1Shade2,
    marginTop: 10,
    textAlign: "center",
  },
  payslipDate: {
    marginHorizontal: 10,
    width: 120
  },
  textLabel: {
    color: palette.$accent1Shade3,
    fontSize: 14,
    fontWeight: "800",
  },
  textAmount: {
    color: palette.$accent1Shade2,
    fontSize: 16,
    fontWeight: "800",
  },
  textHours: {
    color: palette.$accent1Shade3,
    fontSize: 14,
  },
  textDate: {
    color: palette.$accent1Shade3,
    fontSize: 15,
  }
})

export function Payslips({ navigation }) {
  const [payslips, setPayslips] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadPayslips();
    return navigation.addListener('focus', loadPayslips);
  }, []);

  async function loadPayslips() {
    setPayslips([]);
    setLoading(true);
    const payslips = await courseApi.getPayslips();
    payslips.forEach(ps => {
      ps.amount = ps.meetings.map(m => m.fee).reduce((acc, v) => acc + v, 0);
      ps.numHours = ps.meetings.map(m => m.durationMins).reduce((acc, v) => acc + v, 0) / 60;
    });
    setPayslips(payslips);
    setLoading(false);
  }

  return (
    <View style={{ flex: 1 }}>
      {loading && <Spinner/>}
      <ScrollView>
        {!loading && payslips.length === 0 && <Text style={style.textLabel}>No payslips have been issued for you yet</Text>}
        {payslips.filter(payslip => !payslip.paid && !payslip.acceptedByTeacher).sort(byCreationTime).map(payslip => <PayslipItem key={payslip._id} navigation={navigation} payslip={payslip} />)}
        {payslips.filter(payslip => payslip.paid || payslip.acceptedByTeacher).sort(byCreationTime).map(payslip => <PayslipItem key={payslip._id} navigation={navigation} payslip={payslip} />)}
      </ScrollView>
    </View>
  );
  function byCreationTime(a, b) {return b.creationTime - a.creationTime};
}


function PayslipItem({ payslip, navigation }) {
  const payslipNew = !payslip.paid && !payslip.acceptedByTeacher;
  return (
    <TouchableOpacity onPress={() => {
      navigation.navigate("/payslipViewer", { id: payslip._id });
    }}>
      <View style={[style.payslipItem, payslipNew && style.pending]}>
        {payslipNew && <Text style={style.new}>
          NEW
        </Text>}
        <View style={style.payslipInfo}>
          <View style={style.payslipDate}>
            <Text style={style.textLabel}>
              Issued
            </Text>
            <Text style={style.textDate}>
              {moment(payslip.creationTime).format("DD-MMM-YYYY")}
            </Text>
          </View>
          <View>
            <Text style={style.textHours}>
              {payslip.numHours} h taught
            </Text>
            <Text style={style.textAmount}>
              IDR {payslip.amount}
            </Text>
          </View>
          {payslip.paid && <Text style={style.paidStamp}>
            PAID
          </Text>}
        </View>
        {payslipNew && <Text style={style.reviewLabel}>
          Please tap to review
        </Text>}
      </View>
    </TouchableOpacity>

  )
}